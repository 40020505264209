class Tooltip {
    private tooltips: NodeListOf<Element>;
    private scrollStart: number;
    private isLanguageTooltip: boolean;

    constructor() {
        this.tooltips = document.querySelectorAll(".tooltip__wrapper");
        this.scrollStart = 0;
        this.isLanguageTooltip = false;
        this.init();
    }

    // Closes all tooltips that do not contain the target of the given event
    private closeAllTooltips(e: Event): void {
        this.tooltips?.forEach(tooltip => {
            const container = tooltip.querySelector('.tooltip__container');
            if (container && !container.contains(e.target as Node)) {
                container.classList.remove('on');
                container.classList.add('off');
            }
        });
    }

    // Toggles the visibility of the given container
    private onToggle(e: Event, container: Element | null, isLanguageTooltip: boolean): void {
        e.stopPropagation();
        if (isLanguageTooltip) {
            if (container?.classList.contains('off')) {
                this.scrollStart = window.scrollY;
            }
        }
        container?.classList.remove('on', 'off');
        container?.classList.add(container?.classList.contains('on') ? 'off' : 'on');
    }

    // Initializes tooltips and event listeners
    private init(): void {
        document.body.addEventListener('click', (e: Event) => this.closeAllTooltips(e));
        document.body.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.code === 'Escape') {
                this.closeAllTooltips(e);
            }
        });
        window.addEventListener('scroll', () => this.closeAllTooltips(new Event('scroll')));

        Array.from(this.tooltips).forEach((tooltip: Element, index: number) => {
            const triggerId = `trigger-id-${index}`;
            const tooltipId = `tooltip-id-${index}`;

            const triggerContainer = tooltip.querySelector('.tooltip__trigger-container');
            triggerContainer?.setAttribute('id', triggerId);
            triggerContainer?.setAttribute('aria-describedby', tooltipId);

            if (triggerContainer) {
                const languageTooltipButton = triggerContainer.querySelector('.language-tooltip');
                this.isLanguageTooltip = triggerContainer.contains(languageTooltipButton);
            }

            const container = tooltip.querySelector('.tooltip__container');
            container?.setAttribute('id', tooltipId);

            ['click', 'mouseenter', 'mouseleave'].forEach(event => {
                if (event === 'mouseenter' || event === 'mouseleave') {
                    if (!this.isLanguageTooltip) {
                        triggerContainer?.addEventListener(event, (e: Event) => this.onToggle(e, container, this.isLanguageTooltip));
                    }
                } else {
                    triggerContainer?.addEventListener(event, (e: Event) => this.onToggle(e, container, this.isLanguageTooltip));
                }
            });
        });
    }
}

new Tooltip();